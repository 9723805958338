
import dayjs from "dayjs";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
const finance = namespace("finance");
const base = namespace("base");
@Component({})
@Table("loadListData")
export default class InvoiceManage extends Vue {
  @finance.Action getReceiptList;
  @base.Action getDictionaryList;
  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm = {
    receiptTimeFrom: "",
    receiptTimeTo: "",
    payerName: "",
    receiptNo: "",
    receiptType: ""
  };
  receiptList: any[] = [];
  receiptTypeList: any[] = [];
  get searchList() {
    return [
      {
        label: this.$t("finance.receipt-time"),
        type: "timerange",
        value: "",
        prop: "receiptTime"
      },
      {
        label: this.$t("finance.payer"),
        type: "input",
        value: "",
        prop: "payerName"
      },
      {
        label: this.$t("finance.receipt-no"),
        type: "input",
        value: "",
        prop: "receiptNo"
      },
      {
        label: this.$t("finance.proceeds-type"),
        type: "select",
        selectList: this.receiptTypeList,
        value: "",
        prop: "receiptType"
      }
    ];
  }

  created() {
    this.init();
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "receiptTime") {
          this.searchForm.receiptTimeFrom = item.value ? item.value[0] : "";
          this.searchForm.receiptTimeTo = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getReceiptList({
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum
    }).then(data => {
      this.receiptList = this.$safeValue(data, "data.list", []);
      return data;
    });
  }

  handleLinkView(row) {
    const { href } = this.$router.resolve(`/finance/collection-order-view/${row.receiptNo}`);
    window.open(href);
  }
  init() {
    this.loadListData();
    this.getDictionaryList("receipt_type").then(data => {
      this.receiptTypeList = data;
    });
  }
  handleInvoice(row) {
    this.$router.push(`/service/make-invoice/${row.receiptNo}`);
  }
  // -----------------------------
}
